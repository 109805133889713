<template>
  <div class="goods">
    <div class="comheader">
      <div v-for="(tabBar, index) in data" :key="index" class="comdiv">
        <div class="a" :class="{ active: tabIndex == tabBar.id }" @click="toggleTab(tabBar.id)">
          {{ tabBar.name }}
        </div>
        <div v-if="index == 3"></div>
        <div v-else class="comline"></div>
      </div>
      <Goodsure :addDialog="addDialog" @del="del" ref="child" @addsure="addsure"></Goodsure>
      <div><el-button @click="addProduct">发布商品</el-button></div>
      <div><el-button @click="addAllProduct" style="margin-left: 10px">批量发布商品</el-button></div>
    </div>
    <!-- <div v-for="(item,index) in 3" :key="index"> -->
    <div class="commiddle" v-for="(good, index) in getGoodsList" :key="index">
      <router-link :to="{ path: '/commodetial', query: { id: good.id } }" tag="div" class="message">
        <div>
          <img class="comimage" :src="good.minImage" />
          <div class="comspan" v-if="tabIndex == 0">待审核</div>
        </div>
        <div class="conmm">
          <div class="nametext">
            {{ good.name }}
          </div>
          <div>
            <img class="addimage" src="../../assets/position.png" />{{
              good.companyName
            }}
          </div>
        </div>
        <div class="conmms">
          <div>存放地 | {{ good.storageplace }}</div>
          <div>{{ good.createTime }}</div>
        </div>
        <div class="conmmss" v-if="good.price == 0">
          <span>￥</span><span>电议</span>
        </div>
        <div class="conmmss" v-else>
          <span>￥</span><span>{{ good.price }}</span>/吨
        </div>
      </router-link>
      <div v-if="tabIndex == 1">
        <el-button @click="editpub(good.id)" type="danger">编辑</el-button>
      </div>
      <div v-if="tabIndex == 2 || tabIndex == 3">
        <el-button type="danger" @click="lookcause(good.id)">查看原因</el-button>
      </div>
      <div v-if="tabIndex == 1">
        <el-button class="shangjia" v-if="!good.state" @click="putShelf(good.id)">上架</el-button>
        <el-button v-else @click="offShelf(good.id)">下架</el-button>
      </div>
      <div v-if="tabIndex == 2">
        <el-button class="shangjia" @click="cancelGoods(good.id)">删除</el-button>
      </div>
    </div>
    <Cause :addDialog="causeDialog" :resultmessag="resultmessag" :title="title" @del="del"></Cause>
    <!-- </div> -->
    <Public :totaldialog="totaldialog" @clears="clears" @surees="surees" :addpublic="addpublic" :tabIndex="tabIndex"
      :goodid="goodid" ref="mychild"></Public>

    <!-- 分页 -->
    <!-- <el-pagination

      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    >
    </el-pagination> -->
    <Pagination class="el-pagination " ref="changepage" :num="totalCount" :limit="pageSize"
      :totalPageCount="totalPageCount"></Pagination>
    <el-dialog :visible.sync="showAllProduct" v-if="showAllProduct" width="40%" :append-to-body="true"
      :close-on-click-modal="false" :before-close="handleClose">
      <div class="done-up" style="display: flex">
        <div class="upload-left">
          <p>下载模板</p>
          <p>上传图片</p>
          <p>上传模板</p>
        </div>
        <div class="upload-right">
          <div class="FormworkBox">
            <img src="../../assets/excel_bg.jpg" style="position: absolute; width: 90px;
height: 90px" alt="下载模板">
            <a :href=href></a>
            <p>尾易网批量上传商品模板(<span style="color: red">点击图片下载模板</span>)</p>
          </div>
          <div class="uploadBox">
            <div class="uploadImg">
              <input type="text" v-model="imgName" disabled>
              <span v-if="!imgName">选择图片</span>
              <p style="color: red">请添加对应品类图片</p>
            </div>
            <div class="uploadBtn">
              <el-upload class="upload-demo" action="https://www.wyw666.com/api/center/file/upload"
                :headers="{ 'Token': Token }" :on-success="upLoadImgSucc" :show-file-list="false"
                accept=".png, .jpg, .gif" multiple :limit="1" :on-exceed="handleExceedImg" :file-list="imgList">
                <el-button size="small" type="primary" @click="imgList = []">点击上传</el-button>
              </el-upload>
            </div>
          </div>
          <div class="uploadBox">
            <div class="uploadFile">
              <input type="text" v-model="fileName" disabled>
              <span v-if="!fileName">选择文件</span>
            </div>
            <div class="uploadBtn">
              <el-upload action="https://www.wyw666.com/api/goods/goodsManager/addBatchGoods"
                :headers="{ 'Token': Token }" :data="mainimageParams" name="goodsFile" class="upload-demo"
                :on-success="upLoadFileSucc" accept=".pdf, .doc, .docx, .xlsx" multiple :show-file-list="false"
                :limit="1" :on-exceed="handleExceedFile" :on-change="changeFile" ref="upload" :auto-upload="false"
                :file-list="fileList">
                <el-button slot="trigger" size="small" type="primary" @click="fileList = []">点击上传</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">保存</el-button>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Message, MessageBox } from "element-ui";
import Goodsure from "./entre/goodsurelog.vue";
import Pagination from "@/components/prePage/pagination.vue";
import Cause from "./entre/cause.vue";
import Public from "./entre/public.vue";
import goodsApi from "../../api/goodsApi";
import WebIM from "@/utils/WebIM";
import upLoadFile from "@/components/upLoadFile/index.vue";
export default {
  computed: {
    upLoadFile() {
      return upLoadFile
    }
  },
  data() {
    return {
      title: "",
      causeDialog: false,
      addpublic: `false`,
      editpublic: false,
      totaldialog: false,
      editform: {},
      goodid: "",
      shangjia: "上架",
      data: [
        { name: "待审核", id: 0 },
        { name: "已通过", id: 1 },
        { name: "已拒绝", id: 2 },
        { name: "已封禁", id: 3 },
      ],
      tabIndex: 0,
      pageNum: 1,
      pageSize: 4,

      // 总数
      totalCount: 0,
      // 总页数
      totalPageCount: 0,
      addDialog: false,
      // imageUrl:''
      getGoodsList: {},
      // 原因数组
      resultmessag: [],
      showAllProduct: false, // 切换批量显示隐藏
      imgList: [], // 上传图片列表
      fileList: [], // 上传的文件列表
      imgName: '', // 上传的图片名称
      fileName: '', // 上传的文件名称
      mainimage: '', // 上传的图片地址
      mainimageParams: {},
      Token: '',
      href: ''
    };
  },
  components: {
    Goodsure,
    Public,
    Cause,
    Pagination,
  },
  created() {
    this.getGoodsListByState();
    this.Token = localStorage.getItem('Login_data') ? JSON.parse(localStorage.getItem('Login_data')).token : null;
    this.getNum();
  },
  methods: {
    getNum() {
      let num = Math.ceil(Math.random() * 100);
      this.href = 'https://wyw666.oss-cn-shanghai.aliyuncs.com/file/temple/%E5%B0%BE%E6%98%93%E7%BD%91%E6%89%B9%E9%87%8F%E4%B8%8A%E4%BC%A0%E5%95%86%E5%93%81%E6%A8%A1%E6%9D%BF.xlsx?' + num;
    },
    change(even) {
      this.pageNum = even
      this.getGoodsListByState();
    },
    // 查看原因
    lookcause(id) {
      this.causeDialog = true;
      if (this.tabIndex == 2) {
        this.title = "拒绝原因";
        goodsApi.getGoodsRefuseReason({ id: id }, (res) => {
          this.resultmessag = res.data.result;
        });
      }
      if (this.tabIndex == 3) {
        this.title = "禁用原因";
        goodsApi.getGoodsDisableReason({ id: id }, (res) => {
          this.resultmessag = res.data.result;
        });
      }
    },
    //    根据状态获取商品类表
    getGoodsListByState(index) {
      goodsApi.getGoodsListByState(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          state: this.tabIndex,
        },
        (res) => {
          this.getGoodsList = res.data.result.data;
          this.totalCount = res.data.result.totalCount;
          this.totalPageCount = res.data.result.totalPageCount;
          console.log(res.data.result)
          for (let index = 0; index < this.getGoodsList.length; index++) {
            this.getGoodsList[index].createTime = this.$moment(
              this.getGoodsList[index].createTime
            )
              .subtract(10, "days")
              .calendar();
          }
        }
      );
    },
    // 商品上架
    putShelf(id) {
      MessageBox.confirm('确认上架商品吗?', '上架', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then((_) => {
          goodsApi.putShelf({ id: id }, (res) => {
            if (res.data.code != 200) {
              Message.error("修改数据失败");
            } else {
              Message({
                message: "修改成功",
                type: "success",
              });
              this.getGoodsListByState();
            }
          });
        })
        .catch((_) => {
          Message({
            type: 'info',
            message: '已取消上架'
          });
        });

    },
    // 商品下架
    offShelf(id) {
      MessageBox('确认下架商品吗?', '下架', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then((_) => {
          goodsApi.offShelf({ id: id }, (res) => {

            if (res.data.code != 200) {
              Message.error(res.data.message);
            } else {
              Message({
                message: "修改成功",
                type: "success",
              });
              this.getGoodsListByState();
            }
          });
        })
        .catch((_) => {
          Message({
            type: 'info',
            message: '已取消下架'
          });
        });
    },
    // 删除商品
    cancelGoods(id) {
      MessageBox.confirm('确认删除商品吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then((_) => {
          goodsApi.cancelGoods({ id: id }, (res) => {

            if (res.data.code != 200) {
              Message.error(res.data.message);
            } else {
              Message({
                message: "删除成功",
                type: "success",
              });
              this.getGoodsListByState();
            }
          });
        })
        .catch((_) => {
          Message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 分页
    //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getGoodsListByState();
    },
    //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;
      this.getGoodsListByState();
    },
    toggleTab(index) {
      this.tabIndex = index;
      this.$refs.changepage.pageOffset(1)
      //    this.getCollectRecordList()
      this.change(1)
    },
    del(bool) {
      this.addDialog = bool;
      this.causeDialog = bool;
    },
    addsure(bool) {
      this.addDialog = bool;
      this.totaldialog = true;
    },
    // 添加
    addProduct() {
      this.showAllProduct = false;
      if (!localStorage.getItem("goodsReleasesure")) {
        this.$store.commit("intagreement", 'goodsRelease')
        this.tabIndex = 0;
        this.getGoodsListByState();
        this.addDialog = true;
        this.addpublic = true;
        this.$refs.child.getAgreement()
      } else {
        this.$store.commit("intagreement", 'goodsRelease')
        this.tabIndex = 0;
        this.getGoodsListByState();
        this.addDialog = true;
        this.addpublic = true;
        this.$refs.child.getAgreement();
        this.totaldialog = true;
        this.$refs.child.addsure()
      }

    },
    // 批量发布商品
    addAllProduct() {
      this.fileName = '';
      console.log(this.fileName)
      this.showAllProduct = true;
    },
    /**
     *
     * 上传文件模块
     *
    * */
    handleExceedImg(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleExceedFile(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 选择上传图片
    upLoadImgSucc(file, fileList) {
      if (file.code != 200) {
        return Message.error(file.message);
      }
      this.mainimage = file.result;
      this.imgList.push(fileList);
      this.mainimageParams = {
        mainimage: this.mainimage
      }
      this.imgName = fileList.name;
    },
    // 上传文件成功
    upLoadFileSucc(file, fileList) {
      let that = this;
      if (file.code == '200') {
        // this.$alert('上传成功');
        this.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success',
          duration: 2000
        });
        that.handleClose();
        setTimeout(() => {
          this.$router.go(0);
          that.showAllProduct = false;
        }, 1000)
      } else {
        this.$alert(file.message);
        that.handleClose();
      }
    },
    // 选择文件但未上传
    changeFile(file) {
      this.fileName = file.name;
    },
    // 上传文件
    submitUpload() {
      console.log(this.imgList);
      if (this.imgName === '') {
        this.$alert('请先上传图片');
        return;
      }
      if (this.fileName === '') {
        this.$alert('请先上传文件');
        return;
      }
      this.$refs.upload.submit();
    },
    // 关闭批量上传按钮
    handleClose() {
      this.imgName = '';
      this.fileName = '';
      this.fileList = [];
      this.imgList = [];
      this.showAllProduct = false;
    },
    // 编辑
    editpub(id) {
      this.goodid = id;
      goodsApi.getGoodsInfo({ id: this.goodid }, (res) => {
        if (res.data.code != 200) {
          return Message.error(res.data.message);
        }
        this.editform = res.data.result;

        this.$refs.mychild.parentHandleclick(this.editform);
        this.$refs.mychild.gFirstInstitution(this.editform.steelmill);
      });
      this.addDialog = false;
      this.totaldialog = true;

      this.$store.commit("intaddpublic", false);

    },
    clears(bool) {
      this.totaldialog = bool;

    },
    surees(bool) {
      this.totaldialog = bool;

    },
  },
};
</script>

<style lang='scss' scope>
.goods {
  // border: 1px solid red;
  height: 720px;
  position: relative;

  .el-pagination {
    position: absolute;
    bottom: 0;
    // border: 1px solid red;

    left: 50px;
    width: 100%;
    // margin: 0 auto;
  }

  .comheader {
    // border: 1px solid blue;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .comdiv {
    display: flex;
    flex-direction: row;
    width: 100px;
    height: 29px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #868686;
    line-height: 27px;
    cursor: pointer;
    margin-left: 50px;
  }

  .comline {
    width: 1px;
    height: 26px;
    border-right: 1px solid #979797;
    margin-left: 40px;
  }

  .comheader .el-button {
    background: #e94f55;
    color: white;
    height: 39px;
    // width: 80px;
    line-height: 3px;
    margin-left: 90px;
    margin-top: -3px;
  }

  .active {
    color: #e94f55;
  }

  .commiddle {
    .message {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
    }

    cursor: pointer;
    margin-top: 30px;
    padding: 10px;
    width: 100%;
    min-width: 500px;
    // width: 925px;
    // border: 1px solid red;
    background: #ffffff;
    border-radius: 10px;

    .comimage {
      background: #979797;
      width: 100px;
      height: 100px;
    }

    .el-button {
      height: 39px;
      // width: 80px;
      line-height: 10px;
      margin-top: 20px;
      margin-right: 5px;
    }

    .el-button:hover {
      background: #e94f55;
      color: white;
    }

    .shangjia {
      background: #e94f55;
      color: white;
    }

    .comspan {
      height: 20px;
      // margin-top:79px !important;
      width: 100px;
      line-height: 20px;
      position: relative;
      z-index: 0;
      top: -3px;
      left: 0px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }

    .conmm {
      .nametext {
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
      }


      //  border: 1px solid red;
      div:nth-of-type(1) {
        font-size: 16px;
        font-weight: 600;
        height: 45px;
        line-height: 45px;
      }

      div:nth-of-type(2) {
        height: 20px;
        // border: 1px solid red;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
      }

      .addimage {
        width: 12px;
        position: relative;
        top: 3px;
      }
    }

    .conmms {

      color: #333333;

      div:nth-of-type(1) {
        height: 45px;
        line-height: 45px;
      }
    }

    .conmmss {

      height: 80px;
      line-height: 80px;
      font-size: 12px;

      color: rgba(0, 0, 0, 0.56);

      span:nth-of-type(1) {
        font-size: 12px;

        color: #d0111a;
      }

      span:nth-of-type(2) {
        font-size: 16px;
        color: #d0111a;
        font-weight: 600;
      }
    }

    .pagination {
      margin-top: 30px;
    }
  }
}

.bianji {
  margin-right: 10px;
}

.AllProductBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #000000;
    opacity: 0.3;
    z-index: 98;
  }

  .box-card {
    .el-dialog__body {
      position: fixed;
      top: 30%;
      left: 50%;
      transform: translate(-30%, -30%);
      z-index: 99;
      width: 544px;
      height: 500px;
      margin: 80px auto 0;
    }
  }
}

.upload-left {
  width: 80px;
  line-height: 30px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  p {
    width: 80px;
    height: 100px;
  }

  p:nth-child(1) {
    height: 120px;
  }
}

.upload-right {
  line-height: 30px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  div {
    height: 100px;
  }

  .FormworkBox {
    height: 120px;
    position: relative;

    img {
      position: absolute;
      width: 90px;
      height: 90px;
    }

    a {
      position: relative;
      z-index: 2;
      display: block;
      width: 90px;
      height: 90px;
      text-align: end;
    }

    p {
      font-size: 12px;
      color: #999;
    }
  }
}

.uploadBox {
  display: flex;
  height: 30px;
  line-height: 30px;

  .uploadImg {
    position: relative;
    height: 30px;
    line-height: 30px;

    input {
      width: 200px;
      height: 24px;
      padding-left: 5px;
      box-sizing: border-box;
      border: 1px solid #000;
      background: transparent;
    }

    span {
      height: 16px;
      position: absolute;
      box-sizing: border-box;
      border: 1px solid #000;
      top: 7px;
      font-size: 12px;
      line-height: 15px;
      left: 5px;
    }
  }

  .uploadFile {
    position: relative;
    height: 30px;
    line-height: 30px;

    input {
      width: 200px;
      height: 24px;
      padding-left: 5px;
      box-sizing: border-box;
      border: 1px solid #000;
      background: transparent;
    }

    span {
      height: 16px;
      position: absolute;
      border: 1px solid #000;
      top: 7px;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 15px;
      left: 5px;
    }
  }

  .uploadBtn {
    border: none;
    margin-left: 10px;
    //background: transparent;
  }
}
</style>
