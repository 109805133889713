<template>
  <div class="adddialog">
    <el-dialog
      class="dialog"
      :title="title"
      :visible="addDialog"
      width="30%"
      :before-close="del"
      :close-on-click-modal="false"
    >
      <div class="addconfirm">
     
        <div v-for="(item,index) in resultmessag " :key="index">
          <p>
          <span>{{index + 1}}.</span>  {{ item.message }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$moment(item.createtime).format('YYYY年MM月DD日 HH时mm分ss秒')}}
            </p></div>
      </div>

      <div class="addbutton">
        <el-button type="danger" @click="del">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    addDialog: {
      type: Boolean,
      default: false,
    },
    totaldialog: "",
    title: "",
    resultmessag: [],
  },
  methods: {
    del() {
      this.$emit("del", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.adddialog {
  .el-dialog {
    min-width: 460px;
  }
  .dialog {
    text-align: center;

    .addconfirm {
      width: 88%;
      margin: 5px auto;
      text-align: left;
      height: 200px;
    }
  }
  .addconfirm div {
    margin-top: 5px;
  }
  .addchecked {
    width: 80%;
    margin: 5px auto;
    text-align: left;
    height: 50px;
    color: #333333;
    font-size: 14px;
    .el-checkbox__inner {
      border-radius: 50%;
    }
  }
  .addbutton .el-button {
    width: 150px;
    margin: auto;
  }
}
</style>